import "bootstrap/dist/css/bootstrap.min.css";
import { GetUserDetails } from "modules/Login/slice/thunk";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "routes";
import Cookies, { cookieKeys } from "services/cookies";

const App = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const token = Cookies.get(cookieKeys?.TOKEN);
    if (token) {
      dispatch(GetUserDetails());
    }
  }, [dispatch, pathname]);

  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );
};
export default App;
