import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_AREA,
  DELETE_AREA,
  GET_AREA_DETAILS_BY_ID,
  LIST_AREA,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllArea = createAsyncThunk(
  `gigs/GetAllArea`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_AREA}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateAreas = createAsyncThunk(
  `gigs/AddUpdateAreas`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_AREA, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditArea = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_AREA_DETAILS_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteArea = createAsyncThunk(
  `gigs/DeleteArea`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_AREA}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
