import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_PREFERED_TAKE_UP,
  DELETE_PREFERED_TAKE_UP,
  GET_PREFERED_TAKE_UP_BY_ID,
  LIST_PREFERED_TAKE_UP
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllPreferedTakeUp = createAsyncThunk(
  `gigs/GetAllPreferedTakeUp`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_PREFERED_TAKE_UP}`);
      return response.data.data?.takeUp_data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdatePreferedTakeUp = createAsyncThunk(
  `gigs/AddUpdatePreferedTakeUp`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(
        ADD_PREFERED_TAKE_UP,
        payload
      );
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeletePreferedTakeUp = createAsyncThunk(
  `gigs/DeletePreferedTakeUp`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_PREFERED_TAKE_UP}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditPreferedTakeUp = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_PREFERED_TAKE_UP_BY_ID}/${payload}`
      );
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
