import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_CITY,
  ADD_COUNTRY,
  ADD_STATE,
  DELETE_CITY,
  DELETE_COUNTRY,
  DELETE_STATE,
  GET_CITY_DETAILS_BY_ID,
  GET_COUNTRY_DETAILS_BY_ID,
  GET_STATE_DETAILS_BY_ID,
  LIST_CITY,
  LIST_COUNTRY,
  LIST_STATE,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllCity = createAsyncThunk(
  `gigs/GetAllCity`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_CITY}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateCities = createAsyncThunk(
  `gigs/AddUpdateCities`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_CITY, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditCity = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_CITY_DETAILS_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteCity = createAsyncThunk(
  `gigs/DeleteCity`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_CITY}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
