import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateGigs, DeleteGig, EditGig, GetAllGigs } from "./thunk";

const initialState = {
  gigs: [],
  loading: false,
  gigsCount: 0,
  gig: null,
  editLoading: false,
};

const gigSlice = createSlice({
  name: "gigs",
  initialState,
  reducers: {
    clearGig: (state) => {
      state.gig = null;
    },
  },
  extraReducers: (builder) => {
    // List Gigs
    builder.addCase(GetAllGigs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllGigs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.gigs = payload?.data?.user_data || [];
      state.gigsCount = payload?.count;
    });
    builder.addCase(GetAllGigs.rejected, (state) => {
      state.loading = false;
    });
    // AddUpdate Gigs
    builder.addCase(AddUpdateGigs.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateGigs.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.gigs = [...state.gigs, payload?.data?.user_data];
    });
    builder.addCase(AddUpdateGigs.rejected, (state) => {
      state.editLoading = false;
    });
    // Single Gig data edit time
    builder.addCase(EditGig.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditGig.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.gig = payload?.data;
    });
    builder.addCase(EditGig.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete gigs
    builder.addCase(DeleteGig.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteGig.fulfilled, (state, { payload }) => {
      const existingGigs = [...state.gigs];
      const updatedGigs = existingGigs?.filter((x) => x?.user_id !== payload);
      state.editLoading = false;
      state.gigs = updatedGigs;
    });
    builder.addCase(DeleteGig.rejected, (state) => {
      state.editLoading = false;
    });
  },
});
export const { clearGig } = gigSlice.actions;
export default gigSlice.reducer;
