import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_ROLE,
  DELETE_ROLE,
  GER_ROLE_BY_ID,
  GET_PERMISSIONS,
  GET_ROLES,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";
import { clearRoleDetails } from "./reducer";
import { toast } from "react-toastify";

export const GetPermission = createAsyncThunk(
  `permission/GetPermission`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${GET_PERMISSIONS}`);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const ListRole = createAsyncThunk(
  `role/ListRole`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${GET_ROLES}`);
      return response?.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateRoles = createAsyncThunk(
  `role/AddUpdateRoles`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin?.post(ADD_ROLE, payload);
      toast.success(response.data?.message);
      cb(response);
      return response?.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const GetRoleDetailsById = createAsyncThunk(
  `role/getRoleDetailById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GER_ROLE_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const ClearRoleDetails = createAsyncThunk(
  "role/clearRoleDetails",
  async (payload, { dispatch }) => {
    dispatch(clearRoleDetails());
  }
);

export const DeleteRole = createAsyncThunk(
  `role/DeleteRole`,
  async (payload, thunkAPI) => {
    try {
      let response = await axiosPehalGigAdmin.delete(
        `${DELETE_ROLE}/${payload}`
      );
      toast.success(response.data?.message);
      return payload;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
