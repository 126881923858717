import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SidebarData = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isGigManagement, setIsGigManagement] = useState(false);
  const [isStaffMasters, setIsStaffMasters] = useState(false);
  const [isVendorMasters, setIsVendorMasters] = useState(false);
  const [isMasters, setIsMasters] = useState(false);
  const [isJobMasters, setIsJobMasters] = useState(false);
  const [isPermission, setIsPermission] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [isRoleManagement, setIsRoleManagement] = useState(false);
  const [isJobManagement, setJobManagement] = useState(false);
  const [isStaffManagement, setStaffManagement] = useState(false);
  const [isVendorManagement, setVendorManagement] = useState(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (isCurrentState !== "Masters") {
      setIsMasters(false);
    }
  }, [history, isCurrentState, isDashboard]);

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "gig-masters",
      label: "Gig Management",
      icon: "ri-user-3-line",
      link: "gig-management",
      stateVariables: isGigManagement,
      click: function (e) {
        e.preventDefault();
        setIsGigManagement(!isGigManagement);
        setIsCurrentState("Gig Management");
        updateIconSidebar(e);
      },
    },
    {
      id: "role-management",
      label: "Role Management",
      icon: "ri-user-settings-line",
      link: "role-management",
      stateVariables: isRoleManagement,
      click: function (e) {
        e.preventDefault();
        setIsRoleManagement(!isRoleManagement);
        setIsCurrentState("Role Management");
        updateIconSidebar(e);
      },
    },
    {
      id: "job-management",
      label: "Job Management",
      icon: "bx bxs-user-pin",
      link: "job-management",
      stateVariables: isJobManagement,
      click: function (e) {
        e.preventDefault();
        setJobManagement(!isJobManagement);
        setIsCurrentState("Job Management");
        updateIconSidebar(e);
      },
    },
    {
      id: "staff-management",
      label: "Staff Management",
      icon: "ri-group-2-line",
      link: "staff-management",
      stateVariables: isStaffManagement,
      click: function (e) {
        e.preventDefault();
        setStaffManagement(!isStaffManagement);
        setIsCurrentState("Staff Management");
        updateIconSidebar(e);
      },
    },
    {
      id: "vendor-management",
      label: "Client Management",
      icon: "bx bxs-user-pin",
      link: "vendor-management",
      stateVariables: isVendorManagement,
      click: function (e) {
        e.preventDefault();
        setVendorManagement(!isVendorManagement);
        setIsCurrentState("Vendor Management");
        updateIconSidebar(e);
      },
    },
    {
      id: "masters",
      label: "Masters",
      icon: "ri-contacts-line",
      link: "masters",
      stateVariables: isMasters,
      click: function (e) {
        e.preventDefault();
        setIsMasters(!isMasters);
        setIsCurrentState("Masters");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "country",
          label: "Country",
          link: "/country",
          parentId: "vendor-masters",
        },
        {
          id: "state",
          label: "State",
          link: "/state",
          parentId: "vendor-masters",
        },
        {
          id: "city",
          label: "City",
          link: "/city",
          parentId: "vendor-masters",
        },
        {
          id: "area",
          label: "Area",
          link: "/area",
          parentId: "vendor-masters",
        },
        {
          id: "education",
          label: "Education",
          link: "/education",
          parentId: "vendor-masters",
        },
        {
          id: "department",
          label: "Department Role",
          link: "/department",
          parentId: "vendor-masters",
        },
        {
          id: "jobType",
          label: "Job Type",
          link: "/jobType",
          parentId: "vendor-masters",
        },
        {
          id: "regionalLanguagePreference",
          label: "Regional Language Preference",
          link: "/regionalLanguagePreference",
          parentId: "vendor-masters",
        },
        {
          id: "skill",
          label: "Skill",
          link: "/skill",
          parentId: "vendor-masters",
        },
        {
          id: "asset",
          label: "Asset",
          link: "/asset",
          parentId: "vendor-masters",
        },
        {
          id: "degree",
          label: "Degree",
          link: "/degree",
          parentId: "degree-masters",
        },
        {
          id: "preferedLocation",
          label: "Prefered Location",
          link: "/preferedLocation",
          parentId: "preferedLocation-master",
        },
        {
          id: "preferedTakeUp",
          label: "Prefered Take Up",
          link: "/preferedTakeUp",
          parentId: "preferedTakeUp-master",
        },
        {
          id: "preferedTakeUp",
          label: "Industry",
          link: "/industry",
          parentId: "preferedTakeUp-master",
        },
      ],
    },
    {
      id: "category-masters",
      label: "Category Masters",
      icon: "bx bx-category-alt",
      link: "category-masters",
      stateVariables: isCategory,
      click: function (e) {
        e.preventDefault();
        setIsCategory(!isCategory);
        setIsCurrentState("Category Masters");
        updateIconSidebar(e);
      },
    },
    {
      id: "permission",
      label: "Permission",
      icon: "bx bx-sitemap",
      link: "permission",
      stateVariables: isPermission,
      click: function (e) {
        e.preventDefault();
        setIsPermission(!isJobMasters);
        setIsCurrentState("Permission");
        updateIconSidebar(e);
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default SidebarData;
