import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_USER_DETAILS, LOGIN } from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const LoginThunk = createAsyncThunk(
  `authentication/LoginThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(LOGIN, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const GetUserDetails = createAsyncThunk(
  `authentication/GetUserDetails`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(GET_USER_DETAILS);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
