import { Input, Label } from "reactstrap";

const CommonInput = ({
  htmlFor = "",
  label = "",
  name = "",
  className = "",
  placeholder = "",
  type = "",
  onChange = () => {},
  onBlur = () => {},
  value = "",
  isPasswordField = false,
  isRequired = true,
  ...rest
}) => {
  return (
    <>
      <div className={`${isRequired ? "d-flex" : ""}`}>
        <Label htmlFor={htmlFor} className="form-label">
          {label}
        </Label>
        {isRequired && <div className="text-danger">*</div>}
      </div>

      {isPasswordField ? (
        <div className="position-relative auth-pass-inputgroup mb-3">
          <Input
            {...rest}
            name={name}
            className={className}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        </div>
      ) : (
        <Input
          {...rest}
          name={name}
          className={className}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      )}
    </>
  );
};
export default CommonInput;
