import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateAreas, DeleteArea, EditArea, GetAllArea } from "./thunk";

const initialState = {
  areas: [],
  loading: false,
  area: null,
  editLoading: false,
};

const areaSlice = createSlice({
  name: "area",
  initialState,
  extraReducers: (builder) => {
    // List areas
    builder.addCase(GetAllArea.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllArea.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.areas = payload?.data?.area_data || [];
    });
    builder.addCase(GetAllArea.rejected, (state) => {
      state.loading = false;
    });
    // Add areas
    builder.addCase(AddUpdateAreas.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateAreas.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.areas = [...state?.areas, payload?.data];
    });
    builder.addCase(AddUpdateAreas.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditArea.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditArea.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.area = payload?.data;
    });
    builder.addCase(EditArea.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete areas
    builder.addCase(DeleteArea.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteArea.fulfilled, (state, { payload }) => {
      const existingArea = [...state.areas];
      const updatedArea = existingArea?.filter((x) => x?.area_id !== payload);
      state.editLoading = false;
      state.areas = updatedArea;
    });
    builder.addCase(DeleteArea.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default areaSlice.reducer;
