import {
  Area,
  Assets,
  CategoryMasters,
  City,
  Country,
  Dashboard,
  Degree,
  Department,
  Education,
  GigMasters,
  GigProfileDetail,
  JobManagement,
  JobType,
  Login,
  Permission,
  PreferedLocation,
  PreferedTakeUp,
  RegionalLanguagePreference,
  RoleManagement,
  Skill,
  StaffManagement,
  State,
  VendorManagement,
  addUpdateJobManagement,
  Industry,
} from "./routeImports";
import routeConstants from "./routesConstants";

const routesConfig = {
  private: [
    {
      path: routeConstants?.DASHBOARD,
      Component: Dashboard,
    },
    {
      path: routeConstants?.GIG_MASTERS,
      Component: GigMasters,
    },
    {
      path: `${routeConstants?.GIG_PROFILE}/:id?`,
      Component: GigProfileDetail,
    },
    {
      path: routeConstants?.ROLE_MANAGEMENT,
      Component: RoleManagement,
    },
    {
      path: routeConstants?.STAFF_MANAGEMENT,
      Component: StaffManagement,
    },
    {
      path: routeConstants?.VENDOR_MANAGEMENTS,
      Component: VendorManagement,
    },
    {
      path: routeConstants?.JOB_MANAGEMENT,
      Component: JobManagement,
    },
    {
      path: routeConstants?.ADD_UPDATE_JOB_MANAGEMENT,
      Component: addUpdateJobManagement,
      children: [
        {
          index: true,
          path: `${routeConstants?.ADD_UPDATE_JOB_MANAGEMENT}/:id?`,
          Component: addUpdateJobManagement,
        },
      ],
    },
    // Vendors Masters
    {
      path: routeConstants?.MASTERS?.COUNTRY,
      Component: Country,
    },
    {
      path: routeConstants?.MASTERS?.STATE,
      Component: State,
    },
    {
      path: routeConstants?.MASTERS?.CITY,
      Component: City,
    },
    {
      path: routeConstants?.MASTERS?.AREA,
      Component: Area,
    },
    {
      path: routeConstants?.MASTERS?.REGIONAL_LANGUAGE_PREFERENCE,
      Component: RegionalLanguagePreference,
    },
    {
      path: routeConstants?.MASTERS?.SKILL,
      Component: Skill,
    },
    {
      path: routeConstants?.MASTERS?.ASSET,
      Component: Assets,
    },
    {
      path: routeConstants?.MASTERS?.JOB_TYPE,
      Component: JobType,
    },
    {
      path: routeConstants?.MASTERS?.EDUCATION,
      Component: Education,
    },
    {
      path: routeConstants?.MASTERS?.DEGREE,
      Component: Degree,
    },
    {
      path: routeConstants?.MASTERS?.PREFERED_LOCATION,
      Component: PreferedLocation,
    },
    {
      path: routeConstants?.MASTERS?.PREFERED_TAKEUP,
      Component: PreferedTakeUp,
    },
    {
      path: routeConstants?.MASTERS?.DEPARTMENT,
      Component: Department,
    },
    // {
    //   path: routeConstants?.MASTERS?.EDUCATION,
    //   Component: Education,
    // },
    {
      path: routeConstants?.CATEGORY_MASTERS,
      Component: CategoryMasters,
    },
    // Permission
    {
      path: routeConstants?.PERMISSION,
      Component: Permission,
    },
    {
      path: routeConstants?.MASTERS?.INDUSTRY,
      Component: Industry,
    },
  ],
  public: [
    {
      path: routeConstants?.LOGIN,
      Component: Login,
    },
  ],
};

export default routesConfig;
