import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_PERMISSIONS } from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetPermission = createAsyncThunk(
  `permission/GetPermission`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${GET_PERMISSIONS}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
