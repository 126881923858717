import React from "react";
import Select from "react-select";

const CustomMultiSelect = ({
  onChange,
  onBlur,
  value = null,
  option = [],
  name,
  label,
  isStep,
  error,
  isMulti = false,
  isOpenBottom,
  isRequired = true,
  styles = {},
  ...args
}) => {
  const hasError = error && error.length > 0;
  return (
    <>
      <div className={`${isRequired ? "d-flex" : ""}`}>
        <label className="user_box_label">{label}</label>
        {isRequired && <div className="text-danger">*</div>}
      </div>
      <div className="w-100">
        <Select
          className={`${
            isStep ? "select_multi_option_isStep" : "select_multi_option"
          } ${!!hasError ? "invalid" : ""}`}
          name={name}
          isMulti={isMulti}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          options={option}
          backspaceRemovesValue={false}
          {...args}
          styles={styles}
        />
      </div>
      {hasError && <span className="invalid-feedback d-block">{error}</span>}
    </>
  );
};

export default CustomMultiSelect;
