import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_JOB_MANAGEMENT,
  DELETE_JOB_MANAGEMENT,
  GET_JOB_MANAGEMENT_DETAILS_BY_ID,
  LIST_JOB_MANAGEMENT,
  LIST_USER_BY_JOBS,
  UPDATE_JOB_STATUS,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllJobManagement = createAsyncThunk(
  `jobs/GetAllJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_JOB_MANAGEMENT}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateJobManagement = createAsyncThunk(
  `jobs/AddUpdateJobs`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(
        ADD_JOB_MANAGEMENT,
        payload
      );

      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteJobManagement = createAsyncThunk(
  `jobs/DeleteJob`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_JOB_MANAGEMENT}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditJobManagement = createAsyncThunk(
  `jobs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_JOB_MANAGEMENT_DETAILS_BY_ID}/${payload}`
      );

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const ListUserByJobs = createAsyncThunk(
  `jobs/listUserByJobs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${LIST_USER_BY_JOBS}/${payload}`
      );

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const UpdateJobStatus = createAsyncThunk(
  `jobs/updateJobStatus`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(
        UPDATE_JOB_STATUS,
        payload
      );

      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
