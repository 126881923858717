import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateVendorManagements,
  DeleteVendorManagement,
  EditVendorManagement,
  GetAllVendorManagements,
} from "./thunk";

const initialState = {
  vendorManagements: [],
  loading: false,
  vendorManagement: null,
  updateLoading: false,
};

const vendorManagementSlice = createSlice({
  name: "vendorManagements",
  initialState,
  extraReducers: (builder) => {
    // List Vendors
    builder.addCase(GetAllVendorManagements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllVendorManagements.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.vendorManagements = payload?.data?.vendor_data || [];
    });
    builder.addCase(GetAllVendorManagements.rejected, (state) => {
      state.loading = false;
    });
    // AddUpdate Vendors
    builder.addCase(AddUpdateVendorManagements.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(
      AddUpdateVendorManagements.fulfilled,
      (state, { payload }) => {
        state.updateLoading = false;
        // state.vendorManagements = [...state.vendorManagements, payload?.data?.user_data];
      }
    );
    builder.addCase(AddUpdateVendorManagements.rejected, (state) => {
      state.updateLoading = false;
    });
    // Single Gig data edit time
    builder.addCase(EditVendorManagement.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(EditVendorManagement.fulfilled, (state, { payload }) => {
      state.updateLoading = false;
      state.vendorManagement = payload;
    });
    builder.addCase(EditVendorManagement.rejected, (state) => {
      state.updateLoading = false;
    });
    // Delete vendorManagements
    builder.addCase(DeleteVendorManagement.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(DeleteVendorManagement.fulfilled, (state, { payload }) => {
      const existingVendors = [...state.vendorManagements];
      const updatedVendors = existingVendors?.filter(
        (x) => x?.user_id !== payload
      );
      state.updateLoading = false;
      state.vendorManagements = updatedVendors;
    });
    builder.addCase(DeleteVendorManagement.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

export default vendorManagementSlice.reducer;
