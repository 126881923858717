import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_UPDATE_STAFFS,
  DELETE_STAFF,
  GET_ALL_STAFFS,
  GET_USER_DETAILS_BY_ID,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllStaffManagement = createAsyncThunk(
  `staffManagements/GetAllStaffManagement`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_ALL_STAFFS}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateStaffMangement = createAsyncThunk(
  `staffManagements/AddUpdateStaffMangement`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(
        ADD_UPDATE_STAFFS,
        payload
      );
      toast.success(response.data?.message);
      cb(response);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteStaffManagement = createAsyncThunk(
  `staffManagements/DeleteStaffManagement`,
  async (payload, thunkAPI) => {
    try {
      let response = await axiosPehalGigAdmin.delete(
        `${DELETE_STAFF}/${payload}`
      );
      toast.success(response.data?.message);
      return payload;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditStaffManagement = createAsyncThunk(
  `staffManagements/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_USER_DETAILS_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
