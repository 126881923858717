import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateAsset, DeleteAsset, EditAsset, GetAllAsset } from "./thunk";

const initialState = {
  assets: [],
  loading: false,
  asset: null,
  editLoading: false,
};

const jobTypeSlice = createSlice({
  name: "asset",
  initialState,
  extraReducers: (builder) => {
    // List assets
    builder.addCase(GetAllAsset.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllAsset.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.assets = payload?.data?.assets_data || [];
    });
    builder.addCase(GetAllAsset.rejected, (state) => {
      state.loading = false;
    });
    // Add assets
    builder.addCase(AddUpdateAsset.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateAsset.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.assets = [...state?.assets, payload?.data];
    });
    builder.addCase(AddUpdateAsset.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditAsset.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditAsset.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.asset = payload?.data;
    });
    builder.addCase(EditAsset.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete assets
    builder.addCase(DeleteAsset.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteAsset.fulfilled, (state, { payload }) => {
      const existingArea = [...state.assets];
      const updatedArea = existingArea?.filter((x) => x?.area_id !== payload);
      state.editLoading = false;
      state.assets = updatedArea;
    });
    builder.addCase(DeleteAsset.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default jobTypeSlice.reducer;
