import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateCountries,
  DeleteCountry,
  EditCountry,
  GetAllCountry,
} from "./thunk";

const initialState = {
  countries: [],
  loading: false,
  countriesCount: 0,
  editLoading: false,
  country: null,
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  extraReducers: (builder) => {
    // List Country
    builder.addCase(GetAllCountry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllCountry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.countries = payload?.data?.country_data || [];
      state.countriesCount = payload?.count;
    });
    builder.addCase(GetAllCountry.rejected, (state) => {
      state.loading = false;
    });
    // Add Country
    builder.addCase(AddUpdateCountries.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateCountries.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.countries = [...state?.countries, payload?.data];
    });
    builder.addCase(AddUpdateCountries.rejected, (state) => {
      state.editLoading = false;
    });

    // Single country data edit time
    builder.addCase(EditCountry.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditCountry.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.country = payload?.data;
    });
    builder.addCase(EditCountry.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete countries
    builder.addCase(DeleteCountry.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteCountry.fulfilled, (state, { payload }) => {
      const existingCountry = [...state.countries];
      const updatedCountry = existingCountry?.filter(
        (x) => x?.country_id !== payload
      );
      state.editLoading = false;
      state.countries = updatedCountry;
    });
    builder.addCase(DeleteCountry.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default countrySlice.reducer;
