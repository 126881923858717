import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_JOB_TYPE,
  DELETE_JOB_TYPE,
  GET_JOB_TYPE_BY_ID,
  LIST_JOB_MASTER,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllJobType = createAsyncThunk(
  `gigs/GetAllJobType`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_JOB_MASTER}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateJobType = createAsyncThunk(
  `gigs/AddUpdateJobType`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_JOB_TYPE, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditJobType = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_JOB_TYPE_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteJobType = createAsyncThunk(
  `gigs/DeleteJobTypes`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_JOB_TYPE}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
