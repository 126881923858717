import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateCategories,
  DeleteCategory,
  EditCategory,
  GetAllCategories,
} from "./thunk";

const initialState = {
  categories: [],
  loading: false,
  jobCount: 0,
  category: null,
  editLoading: false,
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    // List Categories
    builder.addCase(GetAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories = payload || [];
      state.jobCount = payload?.count;
    });
    builder.addCase(GetAllCategories.rejected, (state) => {
      state.loading = false;
    });

    // AddUpdate Categories
    builder.addCase(AddUpdateCategories.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateCategories.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.categories = [...state.categories, payload?.data?.user_data];
    });
    builder.addCase(AddUpdateCategories.rejected, (state) => {
      state.editLoading = false;
    });
    // Single Category data edit time
    builder.addCase(EditCategory.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditCategory.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.category = payload;
    });
    builder.addCase(EditCategory.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete categories
    builder.addCase(DeleteCategory.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteCategory.fulfilled, (state, { payload }) => {
      const existingCategories = [...state.categories];
      const updatedCategories = existingCategories?.filter(
        (x) => x?.user_id !== payload
      );
      state.editLoading = false;
      state.categories = updatedCategories;
    });
    builder.addCase(DeleteCategory.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default categorySlice.reducer;
