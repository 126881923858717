import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateCities, DeleteCity, EditCity, GetAllCity } from "./thunk";

const initialState = {
  cities: [],
  loading: false,
  citiesCount: 0,
  city: null,
  editLoading: false,
};

const citySlice = createSlice({
  name: "city",
  initialState,
  extraReducers: (builder) => {
    // List State
    builder.addCase(GetAllCity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllCity.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.cities = payload?.data?.city_data || [];
      state.citiesCount = payload?.count;
    });
    builder.addCase(GetAllCity.rejected, (state) => {
      state.loading = false;
    });
    // Add State
    builder.addCase(AddUpdateCities.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateCities.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.cities = [...state?.cities, payload?.data];
    });
    builder.addCase(AddUpdateCities.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditCity.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditCity.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.city = payload?.data;
    });
    builder.addCase(EditCity.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete cities
    builder.addCase(DeleteCity.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteCity.fulfilled, (state, { payload }) => {
      const existingState = [...state.cities];
      const updatedState = existingState?.filter((x) => x?.city_id !== payload);
      state.editLoading = false;
      state.cities = updatedState;
    });
    builder.addCase(DeleteCity.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default citySlice.reducer;
