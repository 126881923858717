import axios from "axios";
// import store from "../store";
import Cookies from "./cookies";

const BASE_URL = "https://pahelapi.quickoninfotech.com/api/v1";
// const BASE_URL =
//   "https://539e-2401-4900-1c80-5404-84cf-dcd2-6c97-420b.ngrok-free.app";
export const PEHAL_GIG_API_URL = `${BASE_URL}`;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = Cookies.get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Token ")
        ? token
        : "Token " + token;
    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response.
    if (response?.data?.data?.token) {
      Cookies.set("token", response.data.data.token);
    }
    return response;
  };

  _responseErr = (error) => {
    //if error comes than handle here
    if (error?.response?.status === 401) {
      Cookies?.clear();
      window.location.replace("/");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosPehalGigAdmin = new Axios(PEHAL_GIG_API_URL).axios;

export { axiosPehalGigAdmin };
