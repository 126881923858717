import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_INDUSTRY,
  DELETE_INDUSTRY,
  GET_CITY_DETAILS_BY_ID,
  LIST_INDUSTRY,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllIndustry = createAsyncThunk(
  `gigs/GetAllIndustry`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_INDUSTRY}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateIndustries = createAsyncThunk(
  `gigs/AddUpdateIndustries`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_INDUSTRY, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditIndustry = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_CITY_DETAILS_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteIndustry = createAsyncThunk(
  `gigs/DeleteIndustry`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_INDUSTRY}/${payload}`);
      cb();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
