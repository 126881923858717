import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateJobType,
  DeleteJobType,
  EditJobType,
  GetAllJobType,
} from "./thunk";

const initialState = {
  jobTypes: [],
  loading: false,
  jobType: null,
  editLoading: false,
};

const jobTypeSlice = createSlice({
  name: "jobType",
  initialState,
  extraReducers: (builder) => {
    // List jobTypes
    builder.addCase(GetAllJobType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllJobType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.jobTypes = payload?.data?.job_type_data || [];
    });
    builder.addCase(GetAllJobType.rejected, (state) => {
      state.loading = false;
    });
    // Add jobTypes
    builder.addCase(AddUpdateJobType.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateJobType.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.jobTypes = [...state?.jobTypes, payload?.data];
    });
    builder.addCase(AddUpdateJobType.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditJobType.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditJobType.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.jobType = payload?.data;
    });
    builder.addCase(EditJobType.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete jobTypes
    builder.addCase(DeleteJobType.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteJobType.fulfilled, (state, { payload }) => {
      const existingArea = [...state.jobTypes];
      const updatedArea = existingArea?.filter((x) => x?.area_id !== payload);
      state.editLoading = false;
      state.jobTypes = updatedArea;
    });
    builder.addCase(DeleteJobType.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default jobTypeSlice.reducer;
