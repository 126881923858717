import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateDegree,
  DeleteDegree,
  EditDegree,
  GetAllDegree,
} from "./thunk";

const initialState = {
  degrees: [],
  loading: false,
  degree: null,
  editLoading: false,
};

const degreeSlice = createSlice({
  name: "degree",
  initialState,
  extraReducers: (builder) => {
    // List State
    builder.addCase(GetAllDegree.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllDegree.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.degrees = payload || [];
    });
    builder.addCase(GetAllDegree.rejected, (state) => {
      state.loading = false;
    });
    // Add State
    builder.addCase(AddUpdateDegree.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateDegree.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.degree = [...state?.degrees, payload?.data];
    });
    builder.addCase(AddUpdateDegree.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditDegree.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditDegree.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.degree = payload?.data;
    });
    builder.addCase(EditDegree.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete degrees
    builder.addCase(DeleteDegree.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteDegree.fulfilled, (state, { payload }) => {
      const existingDegree = [...state.degrees];
      const updatedDegree = existingDegree?.filter(
        (x) => x?.state_id !== payload
      );
      state.editLoading = false;
      state.degrees = updatedDegree;
    });
    builder.addCase(DeleteDegree.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default degreeSlice.reducer;
