import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdatePreferedTakeUp,
  DeletePreferedTakeUp,
  EditPreferedTakeUp,
  GetAllPreferedTakeUp,
} from "./thunk";

const initialState = {
  preferedTakeUps: [],
  loading: false,
  preferedTakeUp: null,
  editLoading: false,
};

const preferedTakeUpSlice = createSlice({
  name: "preferedTakeUps",
  initialState,
  extraReducers: (builder) => {
    // List PreferedLocations
    builder.addCase(GetAllPreferedTakeUp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllPreferedTakeUp.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.preferedTakeUps = payload || [];
    });
    builder.addCase(GetAllPreferedTakeUp.rejected, (state) => {
      state.loading = false;
    });

    // AddUpdate PreferedLocations
    builder.addCase(AddUpdatePreferedTakeUp.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdatePreferedTakeUp.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.preferedTakeUps = [...state.preferedTakeUps, payload?.data?.user_data];
    });
    builder.addCase(AddUpdatePreferedTakeUp.rejected, (state) => {
      state.editLoading = false;
    });
    // Single PreferedLocation data edit time
    builder.addCase(EditPreferedTakeUp.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditPreferedTakeUp.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.preferedTakeUp = payload;
    });
    builder.addCase(EditPreferedTakeUp.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete preferedTakeUps
    builder.addCase(DeletePreferedTakeUp.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeletePreferedTakeUp.fulfilled, (state, { payload }) => {
      const existingCategories = [...state.preferedTakeUps];
      const updatedCategories = existingCategories?.filter(
        (x) => x?.user_id !== payload
      );
      state.editLoading = false;
      state.preferedTakeUps = updatedCategories;
    });
    builder.addCase(DeletePreferedTakeUp.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default preferedTakeUpSlice.reducer;
