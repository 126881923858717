import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateStates, DeleteState, EditState, GetAllState } from "./thunk";

const initialState = {
  states: [],
  loading: false,
  statesCount: 0,
  state: null,
  editLoading: false,
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  extraReducers: (builder) => {
    // List State
    builder.addCase(GetAllState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllState.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.states = payload?.data?.state_data || [];
      state.statesCount = payload?.count;
    });
    builder.addCase(GetAllState.rejected, (state) => {
      state.loading = false;
    });
    // Add State
    builder.addCase(AddUpdateStates.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateStates.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.states = [...state?.states, payload?.data];
    });
    builder.addCase(AddUpdateStates.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditState.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditState.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.state = payload?.data;
    });
    builder.addCase(EditState.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete states
    builder.addCase(DeleteState.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteState.fulfilled, (state, { payload }) => {
      const existingState = [...state.states];
      const updatedState = existingState?.filter(
        (x) => x?.state_id !== payload
      );
      state.editLoading = false;
      state.states = updatedState;
    });
    builder.addCase(DeleteState.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default stateSlice.reducer;
