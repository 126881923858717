import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_DEGREE,
  DELETE_DEGREE,
  GET_DEGREE_BY_ID,
  LIST_DEGREE,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllDegree = createAsyncThunk(
  `gigs/GetAllDegree`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_DEGREE}`);
      return response.data.data.degree_data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateDegree = createAsyncThunk(
  `gigs/AddUpdateDegree`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_DEGREE, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditDegree = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_DEGREE_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteDegree = createAsyncThunk(
  `gigs/DeleteDegree`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_DEGREE}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
