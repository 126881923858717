import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateJobManagement,
  DeleteJobManagement,
  EditJobManagement,
  GetAllJobManagement,
  ListUserByJobs,
  UpdateJobStatus,
} from "./thunk";

const initialState = {
  jobs: [],
  loading: false,
  job: null,
  editLoading: false,
  listUser: null,
};

const jobSlice = createSlice({
  name: "jobs",
  initialState,
  extraReducers: (builder) => {
    // List Jobs
    builder.addCase(GetAllJobManagement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllJobManagement.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.jobs = payload?.data?.job_data || [];
    });
    builder.addCase(GetAllJobManagement.rejected, (state) => {
      state.loading = false;
    });
    // AddUpdate Jobs
    builder.addCase(AddUpdateJobManagement.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateJobManagement.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.jobs = [...state.jobs, payload?.data?.user_data];
    });
    builder.addCase(AddUpdateJobManagement.rejected, (state) => {
      state.editLoading = false;
    });

    // Update User Data
    builder.addCase(UpdateJobStatus.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(UpdateJobStatus.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.jobs = [...state.jobs, payload?.data?.user_data];
    });
    builder.addCase(UpdateJobStatus.rejected, (state) => {
      state.editLoading = false;
    });
    // Single Job data edit time
    builder.addCase(EditJobManagement.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditJobManagement.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.job = payload?.data;
    });
    builder.addCase(EditJobManagement.rejected, (state) => {
      state.editLoading = false;
    });

    // List User By Job data edit time
    builder.addCase(ListUserByJobs.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(ListUserByJobs.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.listUser = payload?.data?.user_data;
    });
    builder.addCase(ListUserByJobs.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete jobs
    builder.addCase(DeleteJobManagement.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteJobManagement.fulfilled, (state, { payload }) => {
      const existingJobs = [...state.jobs];
      const updatedJobs = existingJobs?.filter((x) => x?.user_id !== payload);
      state.editLoading = false;
      state.jobs = updatedJobs;
    });
    builder.addCase(DeleteJobManagement.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default jobSlice.reducer;
