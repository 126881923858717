import { combineReducers } from "redux";
import LayoutReducer from "layout/slice/reducers";
import AuthReducer from "modules/Login/slice/reducers";
import GigReducer from "modules/GigMasters/slice/reducer";
import CountryReducer from "modules/Masters/Country/slice/reducer";
import StateReducer from "modules/Masters/State/slice/reducer";
import CityReducer from "modules/Masters/City/slice/reducer";
import PermissionReducer from "modules/Permission/slice/reducer";
import CategoryReducer from "modules/CategoryMasters/slice/reducer";
import EducationReducer from "modules/Masters/Education/slice/reducer";
import DegreeReducer from "modules/Masters/Degree/slice/reducer";
import PreferedLocationReducer from "modules/Masters/PreferedLocation/slice/reducer";
import PreferedTakeUpReducer from "modules/Masters/PreferedTakeUp/slice/reducer";
import RoleReducer from "modules/RoleManagement/slice/reducer";
import JobManagementReducer from "modules/JobManagement/slice/reducer";
import StaffManagementReducer from "modules/StaffManagement/slice/reducer";
import VendorManagementReducer from "modules/VendorManagement/slice/reducer";
import AreaReducer from "modules/Masters/Area/slice/reducer";
import DepartmentReducer from "modules/Masters/Department/slice/reducer";
import JobTypeReducer from "modules/Masters/JobType/slice/reducer";
import RegionalLanguagePreferenceReducer from "modules/Masters/RegionalLanguagePreference/slice/reducer";
import SkillReducer from "modules/Masters/Skill/slice/reducer";
import AssetReducer from "modules/Masters/Assets/slice/reducer";
import industrySlice from "modules/Masters/Industry/slice/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Auth: AuthReducer,
  Gig: GigReducer,
  Country: CountryReducer,
  State: StateReducer,
  City: CityReducer,
  Area: AreaReducer,
  Permission: PermissionReducer,
  Category: CategoryReducer,
  Education: EducationReducer,
  Degree: DegreeReducer,
  PreferedLocation: PreferedLocationReducer,
  PreferedTakeUp: PreferedTakeUpReducer,
  Role: RoleReducer,
  JobManagement: JobManagementReducer,
  StaffManagement: StaffManagementReducer,
  VendorManagement: VendorManagementReducer,
  Department: DepartmentReducer,
  JobType: JobTypeReducer,
  RegionalLanguagePreference: RegionalLanguagePreferenceReducer,
  Skill: SkillReducer,
  Asset: AssetReducer,
  Industry: industrySlice,
});

export default rootReducer;
