import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_VENDOR,
  DELETE_VENDOR,
  GET_VENDOR_BY_ID,
  LIST_VENDOR_MASTER,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllVendorManagements = createAsyncThunk(
  `gigs/GetAllVendorManagements`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_VENDOR_MASTER}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateVendorManagements = createAsyncThunk(
  `gigs/AddUpdateVendorManagements`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_VENDOR, payload);
      toast.success(response.data?.message);
      cb(response);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteVendorManagement = createAsyncThunk(
  `gigs/DeleteVendorManagement`,
  async (payload, thunkAPI) => {
    try {
      let response = await axiosPehalGigAdmin.delete(
        `${DELETE_VENDOR}/${payload}`
      );
      toast.success(response.data?.message);
      return payload;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditVendorManagement = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_VENDOR_BY_ID}/${payload}`
      );
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
