import CommonInput from "components/common/CommonInput";
import { useFormik } from "formik";
import { useState } from "react";
import {
  Form,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

const initialValues = {
  password: "",
  changePassword: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required."),
  changePassword: Yup.string().required("Change Password is required."),
});

const ChangePassword = ({ isOpen, setIsChangePasswordModal }) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [changePasswordShow, setChangePasswordShow] = useState(false);
  const onSubmit = () => {};

  const closeModal = () => {
    resetForm({
      values: {
        ...initialValues,
      },
    });
    setIsChangePasswordModal(false);
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <Modal id="showModal" isOpen={isOpen} toggle={closeModal} centered>
      <ModalHeader className="bg-light p-3" toggle={closeModal}>
        {"Change Password"}
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              name="password"
              type={passwordShow ? "text" : "password"}
              className="form-control pe-5"
              placeholder="Enter Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.password || ""}
              invalid={touched?.password && !!errors?.password}
            />
            {touched?.password && errors?.password && (
              <FormFeedback type="invalid">{errors.password}</FormFeedback>
            )}
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              id="password-addon"
              onClick={() => setPasswordShow(!passwordShow)}
            >
              <i className="ri-eye-fill align-middle" />
            </button>
          </div>
          <div className="position-relative auth-pass-inputgroup mb-3">
            <Input
              name="changePassword"
              type={changePasswordShow ? "text" : "password"}
              className="form-control pe-5"
              placeholder="Enter Change Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.changePassword || ""}
              invalid={touched?.changePassword && !!errors?.changePassword}
            />
            {touched?.changePassword && errors?.changePassword && (
              <FormFeedback type="invalid">
                {errors.changePassword}
              </FormFeedback>
            )}
            <button
              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
              type="button"
              id="password-addon"
              onClick={() => setChangePasswordShow(!changePasswordShow)}
            >
              <i className="ri-eye-fill align-middle" />
            </button>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="submit"
              className="btn btn-success"
              // disabled={editLoading}
            >
              {/* {editLoading && (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              )} */}
              {"Update"}
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={closeModal}
            >
              {"Cancel"}
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
