import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateStaffMangement, DeleteStaffManagement, EditStaffManagement, GetAllStaffManagement } from "./thunk";

const initialState = {
  staffManagements: [],
  loading: false,
  staffsCount: 0,
  staffManagement: null,
  updateLoading: false,
};

const staffSlice = createSlice({
  name: "staffManagements",
  initialState,
  extraReducers: (builder) => {
    // List StaffManagements
    builder.addCase(GetAllStaffManagement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllStaffManagement.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.staffManagements = payload?.data?.user_data || [];
      state.staffsCount = payload?.count;
    });
    builder.addCase(GetAllStaffManagement.rejected, (state) => {
      state.loading = false;
    });
    // AddUpdate StaffManagements
    builder.addCase(AddUpdateStaffMangement.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(AddUpdateStaffMangement.fulfilled, (state, { payload }) => {
      state.updateLoading = false;
      // state.staffManagements = [...state.staffManagements, payload?.data?.user_data];
    });
    builder.addCase(AddUpdateStaffMangement.rejected, (state) => {
      state.updateLoading = false;
    });
    // Single StaffManagement data edit time
    builder.addCase(EditStaffManagement.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(EditStaffManagement.fulfilled, (state, { payload }) => {
      state.updateLoading = false;
      state.staffManagement = payload?.data;
    });
    builder.addCase(EditStaffManagement.rejected, (state) => {
      state.updateLoading = false;
    });
    // Delete staffManagements
    builder.addCase(DeleteStaffManagement.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(DeleteStaffManagement.fulfilled, (state, { payload }) => {
      const existingStaffs = [...state.staffManagements];
      const updatedStaffs = existingStaffs?.filter(
        (x) => x?.user_id !== payload
      );
      state.updateLoading = false;
      state.staffManagements = updatedStaffs;
    });
    builder.addCase(DeleteStaffManagement.rejected, (state) => {
      state.updateLoading = false;
    });
  },
});

export default staffSlice.reducer;
