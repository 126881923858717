import { ErrorBoundary } from "react-error-boundary";

const FallBack = () => {
  return "Error occurred";
};

const ErrorBoundaryLayout = ({ children }) => {
  return <ErrorBoundary FallbackComponent={FallBack}>{children}</ErrorBoundary>;
};
export default ErrorBoundaryLayout;
