import { createSlice } from "@reduxjs/toolkit";
import Cookies from "services/cookies";
import { GetUserDetails, LoginThunk } from "./thunk";

const token = Cookies.get("token");

export const initialState = {
  isAuth: !!token,
  user: null,
  error: "",
  loading: false,
  token: !!token ? token : "",
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
      state.token = "";
    },
    setLoginError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, { payload }) => {
      state.user = payload?.data?.user_data;
      state.loading = false;
      state.isAuth = true;
    });
    builder.addCase(LoginThunk.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
    builder.addCase(GetUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetUserDetails.fulfilled, (state, { payload }) => {
      state.user = payload?.data?.user_data;
      state.loading = false;
      state.isAuth = true;
    });
    builder.addCase(GetUserDetails.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    });
  },
});

export const { logout, setLoginError } = authSlice?.actions;

export default authSlice.reducer;
