import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateDepartments,
  DeleteDepartment,
  GetAllDepartment,
} from "./thunk";

const initialState = {
  departments: [],
  loading: false,
  editLoading: false,
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  extraReducers: (builder) => {
    // List departments
    builder.addCase(GetAllDepartment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllDepartment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.departments = payload?.data?.department_data?.rows || [];
    });
    builder.addCase(GetAllDepartment.rejected, (state) => {
      state.loading = false;
    });
    // Add departments
    builder.addCase(AddUpdateDepartments.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateDepartments.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.departments = [...state?.departments, payload?.data];
    });
    builder.addCase(AddUpdateDepartments.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete departments
    builder.addCase(DeleteDepartment.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteDepartment.fulfilled, (state, { payload }) => {
      const existingArea = [...state.departments];
      const updatedArea = existingArea?.filter((x) => x?.area_id !== payload);
      state.editLoading = false;
      state.departments = updatedArea;
    });
    builder.addCase(DeleteDepartment.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default departmentSlice.reducer;
