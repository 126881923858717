import CommonInput from "components/common/CommonInput";
import CommonDropdown from "components/common/CommonDropdown";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";

const initialValues = {
  userName: "",
  firstName: "",
  lastName: "",
  gender: "male",
  dateOfBirth: "",
  mobileNo: "",
  email: "",
  role: {
    label: "",
    value: "",
  },
  status: false,
};

const validationSchema = Yup.object().shape({
  userName: Yup.string().required("User Name is required."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  dateOfBirth: Yup.date().required("Date of Birth is required."),
});

const Profile = ({ isOpen, toggle, setIsProfileData }) => {
  const onSubmit = () => {};

  const closeModal = () => {
    resetForm({
      values: {
        ...initialValues,
      },
    });
    setIsProfileData(false);
  };

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      id="showModal"
      isOpen={isOpen}
      toggle={closeModal}
      centered
      style={{ maxWidth: "800px", maxHeight: "00px" }}
    >
      <ModalHeader className="bg-light p-3" toggle={closeModal}>
        {"Profile"}
      </ModalHeader>

      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            {/* <div class="col-md-4 col-md-offset-4 col-sm-6 col-xs-12 profile-badge"> */}
          </Row>
          <Col md={4} className="profile-badge">
            <div className="profile-pic">
              <img
                alt="User Pic"
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/138926-200.png"
                id="profile-image1"
                height="200"
              />
              <input
                // id="profile-image-upload"
                className="d-none"
                type="file"
                // onchange="previewFile()"
              />
              
            </div>
          </Col>
          <Row>
            <Col lg={12}>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <CommonInput
                  name="userName"
                  label="User Name"
                  type="text"
                  className="form-control pe-5"
                  placeholder="Enter User Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.userName || ""}
                  invalid={touched?.userName && !!errors?.userName}
                />
                {touched?.userName && errors?.userName && (
                  <FormFeedback type="invalid">{errors.userName}</FormFeedback>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6}>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <CommonInput
                  name="firstName"
                  label="First Name"
                  type="text"
                  className="form-control pe-5"
                  placeholder="Enter First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.firstName || ""}
                  invalid={touched?.firstName && !!errors?.firstName}
                />
                {touched?.firstName && errors?.firstName && (
                  <FormFeedback type="invalid">{errors.firstName}</FormFeedback>
                )}
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <CommonInput
                  name="lastName"
                  label="Last Name"
                  type={"text"}
                  className="form-control pe-5"
                  placeholder="Enter Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lastName || ""}
                  invalid={touched?.lastName && !!errors?.lastName}
                />
                {touched?.lastName && errors?.lastName && (
                  <FormFeedback type="invalid">{errors.lastName}</FormFeedback>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6}>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <Label for="birthdayidInput" className="form-label">
                  Date of Birth
                </Label>
                <Flatpickr
                  className={`form-control ${
                    touched?.dateOfBirth && errors?.dateOfBirth
                      ? "is-invalid"
                      : ""
                  }`}
                  options={{
                    dateFormat: "d M, Y",
                  }}
                  placeholder="Select Date"
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={(selectedDates) => {
                    setFieldValue("dateOfBirth", selectedDates[0]);
                  }}
                  onBlur={handleBlur}
                  invalid={touched?.dateOfBirth && !!errors?.dateOfBirth}
                />
              </div>
              {touched?.dateOfBirth && !!errors?.dateOfBirth && (
                <div className="invalid-feedback d-block">
                  {errors.dateOfBirth}
                </div>
              )}
            </Col>
            <Col md={6} lg={6}>
              <FormGroup tag="fieldset">
                <Label for="gender" className="form-label me-3">
                  Gender
                </Label>
                <div className="d-flex">
                  <FormGroup check className="me-5">
                    <Label check>
                      <Input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={values?.gender === "male"}
                        onChange={handleChange}
                      />{" "}
                      Male
                    </Label>
                  </FormGroup>
                  <FormGroup check className="me-5">
                    <Label check>
                      <Input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={values?.gender === "female"}
                        onChange={handleChange}
                      />{" "}
                      Female
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="gender"
                        value="other"
                        checked={values?.gender === "other"}
                        onChange={handleChange}
                      />{" "}
                      Other
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6}>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <CommonInput
                  label="Mobile No"
                  type="text"
                  name="mobileNo"
                  className="form-control"
                  placeholder="Enter Mobile No."
                  value={values?.mobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched?.mobileNo && !!errors?.mobileNo}
                />
                {touched?.mobileNo && !!errors?.mobileNo ? (
                  <FormFeedback type="invalid">{errors.mobileNo}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="position-relative auth-pass-inputgroup mb-3">
                <CommonInput
                  label="Email"
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Enter Email."
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched?.email && !!errors?.email}
                />
                {touched?.email && !!errors?.email ? (
                  <FormFeedback type="invalid">{errors.email}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12}>
              <CommonDropdown
                error={
                  errors?.state?.value && touched?.state?.value
                    ? errors?.state?.value
                    : ""
                }
                label="Assign Role"
                placeholder="Select Role"
                // option={stateOption}
                name="role"
                id="role"
                value={values?.role?.value ? values?.role : null}
                // viewMode={viewMode ? true : false}
                onChange={(e) => setFieldValue("role", e)}
              />
            </Col>
          </Row>
          <div className="mb-3 mt-3">
            <div
              className="form-check form-switch form-switch-success form-switch-lg"
              dir="ltr"
            >
              <Input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizelg"
                name="status"
                onChange={handleChange}
                checked={values?.status}
              />
              <Label className="form-check-label" htmlFor="customSwitchsizelg">
                Status
              </Label>
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="submit"
              className="btn btn-success"
              // disabled={editLoading}
            >
              {/* {editLoading && (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              )} */}

              {"Update"}
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={closeModal}
            >
              {"Cancel"}
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default Profile;
