import { createSlice } from "@reduxjs/toolkit";
import { GetPermission } from "./thunk";

const initialState = {
  permissions: [],
  loading: false,
  loadingPermissions: false,
  loadingDeleteButton: false,
};

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(GetPermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetPermission?.fulfilled, (state, { payload }) => {
      const updatedPermissions = payload?.data?.data;
      const newArray = [];
      updatedPermissions.forEach((element) => {
        element.page = !!element?.child_data?.length
          ? `${element?.menu_name}`
          : "-";

        newArray.push(element);
        if (!!element?.child_data?.length) {
          element?.child_data?.forEach((childElement) => {
            childElement.page = `${element?.menu_name}`;
            newArray?.push(childElement);
          });
        }
      });
      state.loading = false;
      state.permissions = newArray;
    });
    builder.addCase(GetPermission.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default permissionSlice.reducer;
