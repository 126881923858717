import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORY_BY_ID,
  LIST_CATEGORY,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllCategories = createAsyncThunk(
  `gigs/GetAllCategories`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_CATEGORY}`);
      return response.data.data?.job_category_data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateCategories = createAsyncThunk(
  `gigs/AddUpdateCategories`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_CATEGORY, payload);
      cb(response);
      toast.success(response.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteCategory = createAsyncThunk(
  `gigs/DeleteCategory`,
  async (payload, thunkAPI) => {
    try {
      let response = await axiosPehalGigAdmin.delete(
        `${DELETE_CATEGORY}/${payload}`
      );
      toast.success(response.data?.message);
      return payload;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditCategory = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_CATEGORY_BY_ID}/${payload}`
      );
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
