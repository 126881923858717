import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_EDUCATION,
  DELETE_EDUCATION,
  GET_EDUCATION_BY_ID,
  LIST_EDUCATION,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllEducation = createAsyncThunk(
  `gigs/GetAllEducation`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_EDUCATION}`);
      return response?.data?.data?.education_data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateEducations = createAsyncThunk(
  `gigs/AddUpdateEducations`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_EDUCATION, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteEducation = createAsyncThunk(
  `gigs/DeleteEducation`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_EDUCATION}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditEducation = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_EDUCATION_BY_ID}/${payload}`
      );
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
