const routeConstants = {
  DASHBOARD: "dashboard",
  LOGIN: "login",
  GIG_MASTERS: "gig-management",
  GIG_PROFILE: "/gig-profile",
  JOB_MANAGEMENT: "job-management",
  STAFF_MANAGEMENT: "staff-management",
  ADD_UPDATE_JOB_MANAGEMENT: "/addUpdateJobManagement",
  STAFF_MASTERS: "staff-masters",
  ROLE_MANAGEMENT: "role-management",
  VENDOR_MASTERS: "vendor-masters",
  VENDOR_MANAGEMENTS: "vendor-Management",
  CATEGORY_MASTERS: "category-masters",
  MASTERS: {
    COUNTRY: "country",
    STATE: "state",
    CITY: "city",
    AREA: "area",
    EDUCATION: "education",
    JOB_TYPE: "jobType",
    DEGREE: "degree",
    DEPARTMENT: "department",
    REGIONAL_LANGUAGE_PREFERENCE: "regionalLanguagePreference",
    SKILL: "skill",
    ASSET: "asset",
    PREFERED_LOCATION: "preferedLocation",
    PREFERED_TAKEUP: "preferedTakeUp",
    INDUSTRY: "industry",
  },
  JOB_MASTERS: "job-masters",
  PERMISSION: "/permission",
};

export default routeConstants;
