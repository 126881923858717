import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SKILL,
  DELETE_SKILL,
  GET_SKILL_BY_ID,
  LIST_SKILLS_MASTER,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllSkill = createAsyncThunk(
  `gigs/GetAllSkill`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_SKILLS_MASTER}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateSkill = createAsyncThunk(
  `gigs/AddUpdateSkill`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_SKILL, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditSkill = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_SKILL_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteSkill = createAsyncThunk(
  `gigs/DeleteSkill`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_SKILL}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
