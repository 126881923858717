import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_UPDATE_GIGS,
  DELETE_GIG,
  GET_ALL_GIGS,
  GET_USER_DETAILS_BY_ID,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllGigs = createAsyncThunk(
  `gigs/GetAllGigs`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_ALL_GIGS}/${payload}`
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateGigs = createAsyncThunk(
  `gigs/AddUpdateGigs`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_UPDATE_GIGS, payload);
      cb(response);
      toast.success(response.data?.message);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteGig = createAsyncThunk(
  `gigs/DeleteGig`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_GIG}/${payload}`);
      return payload;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditGig = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_USER_DETAILS_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
