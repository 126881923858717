import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_CATEGORY,
  ADD_PREFERED_LOCATION,
  DELETE_CATEGORY,
  DELETE_PREFERED_LOCATION,
  GET_CATEGORY_BY_ID,
  GET_PREFERED_LOCATION_BY_ID,
  LIST_CATEGORY,
  LIST_PREFERED_LOCATION,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllPreferedLocation = createAsyncThunk(
  `gigs/GetAllPreferedLocation`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${LIST_PREFERED_LOCATION}`
      );
      return response.data.data?.location_data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdatePreferLocation = createAsyncThunk(
  `gigs/AddUpdatePreferedLocation`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(
        ADD_PREFERED_LOCATION,
        payload
      );
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeletePreferedLocation = createAsyncThunk(
  `gigs/DeletePreferedLocation`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_PREFERED_LOCATION}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditPreferedLocation = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_PREFERED_LOCATION_BY_ID}/${payload}`
      );
      return response.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
