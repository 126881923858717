import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_COUNTRY,
  DELETE_COUNTRY,
  GET_COUNTRY_DETAILS_BY_ID,
  LIST_COUNTRY,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllCountry = createAsyncThunk(
  `gigs/GetAllCountry`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(`${LIST_COUNTRY}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateCountries = createAsyncThunk(
  `gigs/AddUpdateCountries`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_COUNTRY, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditCountry = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${GET_COUNTRY_DETAILS_BY_ID}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteCountry = createAsyncThunk(
  `gigs/DeleteCountry`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(`${DELETE_COUNTRY}/${payload}`);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
