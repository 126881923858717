import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateRoles,
  ClearRoleDetails,
  DeleteRole,
  GetPermission,
  GetRoleDetailsById,
  ListRole,
} from "./thunk";

const initialState = {
  loading: false,
  allPermissions: [],
  roles: [],
  roleLoading: false,
  deleteRoleLoading: false,
  roleDetails: null,
  updateLoading: false,
};

const addIndex = (arr = []) => {
  return arr?.map((item, index) => {
    const currentIndex = index;
    return {
      ...item,
      index: currentIndex,
      child_data: !!item.child_data.length
        ? addIndex(item.child_data, currentIndex)
        : [],
    };
  });
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    clearRoleDetails: (state) => {
      state.roleDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetPermission.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetPermission?.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allPermissions = addIndex(payload?.data?.data);
    });
    builder.addCase(GetPermission.rejected, (state) => {
      state.loading = false;
    });
    // List Role
    builder.addCase(ListRole?.pending, (state) => {
      state.roleLoading = true;
    });
    builder.addCase(ListRole?.fulfilled, (state, { payload }) => {
      state.roleLoading = false;
      state.roles = payload?.data?.data;
    });
    builder.addCase(ListRole?.rejected, (state) => {
      state.roleLoading = false;
    });

    // AddUpdate Role
    builder.addCase(AddUpdateRoles?.pending, (state) => {
      state.roleLoading = true;
    });
    builder.addCase(AddUpdateRoles?.fulfilled, (state, { payload }) => {
      state.roleLoading = false;
    });
    builder.addCase(AddUpdateRoles?.rejected, (state) => {
      state.roleLoading = false;
    });

    // get role detail by id
    builder.addCase(GetRoleDetailsById.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(GetRoleDetailsById.fulfilled, (state, { payload }) => {
      const roleDetails = {
        title: payload?.data?.profile_data?.title,
        permissions: addIndex(payload?.data?.permission_data),
        isActive: payload?.data?.profile_data?.is_active,
      };
      state.roleDetails = roleDetails;
      state.updateLoading = false;
    });
    builder.addCase(GetRoleDetailsById.rejected, (state) => {
      state.updateLoading = false;
    });

    // Clear RoleDetails
    builder
      .addCase(ClearRoleDetails.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(ClearRoleDetails.fulfilled, (state) => {
        state.updateLoading = false;
        state.roleDetails = null;
      })
      .addCase(ClearRoleDetails.rejected, (state) => {
        state.updateLoading = false;
      });

    // Delete Role
    builder.addCase(DeleteRole.pending, (state) => {
      state.deleteRoleLoading = true;
    });
    builder.addCase(DeleteRole.fulfilled, (state, { payload }) => {
      const existingRoles = [...state.roles];
      const updatedRoles = existingRoles?.filter(
        (x) => x?.permission_profile_id !== payload
      );
      state.deleteRoleLoading = false;
      state.roles = updatedRoles;
    });
    builder.addCase(DeleteRole.rejected, (state) => {
      state.deleteRoleLoading = false;
    });
  },
});

export const { clearRoleDetails } = roleSlice.actions;
export default roleSlice.reducer;
