import lazy from "utils/lazy";

// Login
export const Login = lazy(() => import("../modules/Login"));

// Dashboard
export const Dashboard = lazy(() => import("../modules/Dashboard"));

// Gig Masters
export const GigMasters = lazy(() => import("../modules/GigMasters"));

// Role Management
export const RoleManagement = lazy(() => import("../modules/RoleManagement"));

// Vendor Masters

export const Country = lazy(() => import("../modules/Masters/Country"));
export const State = lazy(() => import("../modules/Masters/State"));
export const City = lazy(() => import("../modules/Masters/City"));
export const Area = lazy(() => import("../modules/Masters/Area"));
export const JobType = lazy(() => import("../modules/Masters/JobType"));
export const RegionalLanguagePreference = lazy(() =>
  import("../modules/Masters/RegionalLanguagePreference")
);
export const Education = lazy(() => import("../modules/Masters/Education"));
export const Department = lazy(() => import("../modules/Masters/Department"));
export const Degree = lazy(() => import("../modules/Masters/Degree"));
export const Skill = lazy(() => import("../modules/Masters/Skill"));
export const Assets = lazy(() => import("../modules/Masters/Assets"));
export const Industry = lazy(() => import("../modules/Masters/Industry"));
export const PreferedLocation = lazy(() =>
  import("../modules/Masters/PreferedLocation")
);
export const PreferedTakeUp = lazy(() =>
  import("../modules/Masters/PreferedTakeUp")
);

//Permission
export const Permission = lazy(() => import("../modules/Permission"));

// Category
export const CategoryMasters = lazy(() => import("../modules/CategoryMasters"));

//Job Management
export const JobManagement = lazy(() => import("../modules/JobManagement"));
export const addUpdateJobManagement = lazy(() =>
  import("../modules/JobManagement/components/AddUpdateJobManagements")
);

export const GigProfileDetail = lazy(() =>
  import("../modules/JobManagement/components/GigUserProfile")
);

// Staff Management
export const StaffManagement = lazy(() => import("../modules/StaffManagement"));

// Vendor Management
export const VendorManagement = lazy(() =>
  import("../modules/VendorManagement")
);
