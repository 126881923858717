import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_DEPARTMENT,
  DELETE_DEPARTMENT,
  LIST_DEPARTMENT_MASTER,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllDepartment = createAsyncThunk(
  `gigs/GetAllDepartment`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${LIST_DEPARTMENT_MASTER}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateDepartments = createAsyncThunk(
  `gigs/AddUpdateDepartments`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(ADD_DEPARTMENT, payload);
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteDepartment = createAsyncThunk(
  `gigs/DeleteDepartment`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      let response = await axiosPehalGigAdmin.delete(
        `${DELETE_DEPARTMENT}/${payload}`
      );
      if (response?.data) {
        cb();
        return payload;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
