import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import avatar1 from "assets/images/users/avatar-1.jpg";
import Cookies from "services/cookies";
import ChangePassword from "modules/ChangePassword/ChangePassword";
import Profile from "modules/Profile/Profile";

const ProfileDropdown = ({ user }) => {
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);
  const [isProfileData, setIsProfileData] = useState(false);

  const handleLogOut = () => {
    Cookies.clear();
    window.location.reload();
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const toggleAddUpdateModal = () => {
    setIsChangePasswordModal(!isChangePasswordModal);
  };

  const toggleProfileModal = () => {
    setIsProfileData(!isProfileData);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={avatar1}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user?.first_name} {user?.last_name}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem className="p-0">
            <div className="dropdown-item" /* onClick={handleLogOut} */>
              <i className="mdi mdi-account text-muted fs-16 align-middle me-1"></i>{" "}
              <span
                className="align-middle"
                data-key="t-profile"
                onClick={toggleProfileModal}
              >
                Profile
              </span>
            </div>
          </DropdownItem>
          <DropdownItem className="p-0">
            <div
              className="dropdown-item"
              onClick={() => setIsChangePasswordModal(!isChangePasswordModal)}
            >
              <i className="mdi mdi-lock-reset text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-password">
                Change Password
              </span>
            </div>
          </DropdownItem>
          <DropdownItem className="p-0">
            <div className="dropdown-item" onClick={handleLogOut}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </div>
          </DropdownItem>
          <DropdownItem className="p-0" disabled>
            <div className="dropdown-item text-center" onClick={handleLogOut}>
              <span className="align-middle" data-key="t-logout">
                Version 0.0.1
              </span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ChangePassword
        isOpen={isChangePasswordModal}
        toggle={toggleAddUpdateModal}
        setIsChangePasswordModal={setIsChangePasswordModal}
      />
      <Profile
        isOpen={isProfileData}
        toggle={toggleProfileModal}
        setIsProfileData={setIsProfileData}
      />
    </React.Fragment>
  );
};
export default ProfileDropdown;
