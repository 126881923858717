import { createSlice } from "@reduxjs/toolkit";
import { AddUpdateSkill, DeleteSkill, EditSkill, GetAllSkill } from "./thunk";

const initialState = {
  skills: [],
  loading: false,
  skill: null,
  editLoading: false,
};

const jobTypeSlice = createSlice({
  name: "skill",
  initialState,
  extraReducers: (builder) => {
    // List skills
    builder.addCase(GetAllSkill.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllSkill.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.skills = payload?.data?.skill_data || [];
    });
    builder.addCase(GetAllSkill.rejected, (state) => {
      state.loading = false;
    });
    // Add skills
    builder.addCase(AddUpdateSkill.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateSkill.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.skills = [...state?.skills, payload?.data];
    });
    builder.addCase(AddUpdateSkill.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditSkill.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditSkill.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.skill = payload?.data;
    });
    builder.addCase(EditSkill.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete skills
    builder.addCase(DeleteSkill.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteSkill.fulfilled, (state, { payload }) => {
      const existingArea = [...state.skills];
      const updatedArea = existingArea?.filter((x) => x?.area_id !== payload);
      state.editLoading = false;
      state.skills = updatedArea;
    });
    builder.addCase(DeleteSkill.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default jobTypeSlice.reducer;
