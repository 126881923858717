import React from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const PageLoader = (props) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center vh-100 align-items-center">
        <Spinner color="primary">Loading...</Spinner>
      </div>
      {toast.error(props.error, {
        position: "top-right",
        hideProgressBar: false,
        progress: undefined,
        toastId: "",
      })}
    </React.Fragment>
  );
};
export default PageLoader;
