import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdatePreferLocation,
  DeletePreferedLocation,
  EditPreferedLocation,
  GetAllPreferedLocation,
} from "./thunk";

const initialState = {
  preferedLocations: [],
  loading: false,
  preferedLocation: null,
  editLoading: false,
};

const preferedLocationSlice = createSlice({
  name: "preferedLocations",
  initialState,
  extraReducers: (builder) => {
    // List PreferedLocations
    builder.addCase(GetAllPreferedLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllPreferedLocation.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.preferedLocations = payload || [];
    });
    builder.addCase(GetAllPreferedLocation.rejected, (state) => {
      state.loading = false;
    });

    // AddUpdate PreferedLocations
    builder.addCase(AddUpdatePreferLocation.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdatePreferLocation.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.preferedLocations = [...state.preferedLocations, payload?.data?.user_data];
    });
    builder.addCase(AddUpdatePreferLocation.rejected, (state) => {
      state.editLoading = false;
    });
    // Single PreferedLocation data edit time
    builder.addCase(EditPreferedLocation.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditPreferedLocation.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.preferedLocation = payload;
    });
    builder.addCase(EditPreferedLocation.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete preferedLocations
    builder.addCase(DeletePreferedLocation.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeletePreferedLocation.fulfilled, (state, { payload }) => {
      const existingCategories = [...state.preferedLocations];
      const updatedCategories = existingCategories?.filter(
        (x) => x?.user_id !== payload
      );
      state.editLoading = false;
      state.preferedLocations = updatedCategories;
    });
    builder.addCase(DeletePreferedLocation.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default preferedLocationSlice.reducer;
