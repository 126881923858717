import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateIndustries,
  DeleteIndustry,
  EditIndustry,
  GetAllIndustry,
} from "./thunk";

const initialState = {
  industries: [],
  loading: false,
  industriesCount: 0,
  industry: null,
  editLoading: false,
};

const industrySlice = createSlice({
  name: "industry",
  initialState,
  extraReducers: (builder) => {
    // List State
    builder.addCase(GetAllIndustry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllIndustry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industries = payload?.data?.industries_data?.rows || [];
      state.industriesCount = payload?.data?.industries_data?.count || 0;
    });
    builder.addCase(GetAllIndustry.rejected, (state) => {
      state.loading = false;
    });
    // Add State
    builder.addCase(AddUpdateIndustries.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateIndustries.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.cities = [...state?.cities, payload?.data];
    });
    builder.addCase(AddUpdateIndustries.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditIndustry.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditIndustry.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.industry = payload?.data;
    });
    builder.addCase(EditIndustry.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete cities
    builder.addCase(DeleteIndustry.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteIndustry.fulfilled, (state, { payload }) => {
      const existingState = [...state.industries];
      const updatedState = existingState?.filter((x) => x?._id !== payload);
      state.editLoading = false;
      state.cities = updatedState;
    });
    builder.addCase(DeleteIndustry.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default industrySlice.reducer;
