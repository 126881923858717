import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateRegional,
  DeleteRegional,
  EditRegional,
  GetAllRegional,
} from "./thunk";

const initialState = {
  regionalLanguagePreferences: [],
  loading: false,
  regionalLanguagePreference: null,
  editLoading: false,
};

const regionalLanguagePreferenceSlice = createSlice({
  name: "regionalLanguagePreference",
  initialState,
  extraReducers: (builder) => {
    // List regionalLanguagePreferences
    builder.addCase(GetAllRegional.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllRegional.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.regionalLanguagePreferences =
        payload?.data?.regional_language_data || [];
    });
    builder.addCase(GetAllRegional.rejected, (state) => {
      state.loading = false;
    });
    // Add regionalLanguagePreferences
    builder.addCase(AddUpdateRegional.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateRegional.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.regionalLanguagePreferences = [...state?.regionalLanguagePreferences, payload?.data];
    });
    builder.addCase(AddUpdateRegional.rejected, (state) => {
      state.editLoading = false;
    });

    // Single state data edit time
    builder.addCase(EditRegional.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditRegional.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.regionalLanguagePreference = payload?.data;
    });
    builder.addCase(EditRegional.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete regionalLanguagePreferences
    builder.addCase(DeleteRegional.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteRegional.fulfilled, (state, { payload }) => {
      const existingArea = [...state.regionalLanguagePreferences];
      const updatedArea = existingArea?.filter((x) => x?.area_id !== payload);
      state.editLoading = false;
      state.regionalLanguagePreferences = updatedArea;
    });
    builder.addCase(DeleteRegional.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default regionalLanguagePreferenceSlice.reducer;
