import { createSlice } from "@reduxjs/toolkit";
import {
  AddUpdateCategories,
  AddUpdateEducations,
  DeleteCategory,
  DeleteEducation,
  EditCategory,
  EditEducation,
  GetAllCategories,
  GetAllEducation,
} from "./thunk";

const initialState = {
  educations: [],
  loading: false,
  education: null,
  editLoading: false,
};

const educationSlice = createSlice({
  name: "educations",
  initialState,
  extraReducers: (builder) => {
    // List Educations
    builder.addCase(GetAllEducation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllEducation.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.educations = payload || [];
    });
    builder.addCase(GetAllEducation.rejected, (state) => {
      state.loading = false;
    });

    // AddUpdate Educations
    builder.addCase(AddUpdateEducations.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(AddUpdateEducations.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      // state.educations = [...state.educations, payload?.data?.user_data];
    });
    builder.addCase(AddUpdateEducations.rejected, (state) => {
      state.editLoading = false;
    });
    // Single Education data edit time
    builder.addCase(EditEducation.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(EditEducation.fulfilled, (state, { payload }) => {
      state.editLoading = false;
      state.education = payload;
    });
    builder.addCase(EditEducation.rejected, (state) => {
      state.editLoading = false;
    });
    // Delete educations
    builder.addCase(DeleteEducation.pending, (state) => {
      state.editLoading = true;
    });
    builder.addCase(DeleteEducation.fulfilled, (state, { payload }) => {
      const existingEducations = [...state.educations];
      const educations = existingEducations?.filter(
        (x) => x?.user_id !== payload
      );
      state.editLoading = false;
      state.educations = educations;
    });
    builder.addCase(DeleteEducation.rejected, (state) => {
      state.editLoading = false;
    });
  },
});

export default educationSlice.reducer;
