export const LOGIN = "/adminLogin";

// Gigs
export const GET_USER_DETAILS = "/getUserDetails";
export const GET_ALL_GIGS = "/listUser";
export const ADD_UPDATE_GIGS = "/createUser";
export const DELETE_GIG = "/deleteUser";
export const GET_USER_DETAILS_BY_ID = "/getUserDetailsById";

// Job Management

export const LIST_JOB_MANAGEMENT = "/listJobs";
export const ADD_JOB_MANAGEMENT = "/saveJob";
export const DELETE_JOB_MANAGEMENT = "/deleteMainJob";
export const GET_JOB_MANAGEMENT_DETAILS_BY_ID = "/getJobDetailById";
export const LIST_USER_BY_JOBS = "/listUserByJobs";
export const UPDATE_JOB_STATUS = "/updateJobStatus";

// Country

export const LIST_COUNTRY = "/listCountry";
export const ADD_COUNTRY = "/createCountry";
export const DELETE_COUNTRY = "/deleteCountry";
export const GET_COUNTRY_DETAILS_BY_ID = "/getCountryDetailsById";

// State

export const LIST_STATE = "/liststate";
export const ADD_STATE = "/createstate";
export const DELETE_STATE = "/deletestate";
export const GET_STATE_DETAILS_BY_ID = "/getstateDetailsById";

// City

export const LIST_CITY = "/listcity";
export const ADD_CITY = "/createcity";
export const DELETE_CITY = "/deletecity";
export const GET_CITY_DETAILS_BY_ID = "/getcityDetailsById";

// Area

export const LIST_AREA = "/listArea";
export const ADD_AREA = "/createArea";
export const DELETE_AREA = "/deleteArea";
export const GET_AREA_DETAILS_BY_ID = "/getAreaDetailsById";
//Stuff

export const GET_ALL_STAFFS = "/listUser";
export const ADD_UPDATE_STAFFS = "/createUser";
export const DELETE_STAFF = "/deleteUser";

// Permission
export const GET_PERMISSIONS = "/getPermissions";

// Job Master

export const LIST_JOB = "/listJobMaster";
export const ADD_JOB = "/saveJobMaster";
export const DELETE_JOB = "/deleteJob";
export const GET_JOB_DETAILS_BY_ID = "/getJobMasterById";
export const GET_JOB_CATEGORY = "/listJobCategoryMaster";

// Category Master

export const LIST_CATEGORY = "/listJobCategoryMaster";
export const ADD_CATEGORY = "/saveJobCategoryMaster";
export const DELETE_CATEGORY = "/deleteJobCategory";
export const GET_CATEGORY_BY_ID = "/getJobCategoryMasterById";

// Education Master

export const LIST_EDUCATION = "/listEducationMaster";
export const ADD_EDUCATION = "/saveEducationMaster";
export const DELETE_EDUCATION = "/deleteEducationMaster";
export const GET_EDUCATION_BY_ID = "/getEducationMasterById";

// Degree
export const LIST_DEGREE = "/listDegreeMaster";
export const ADD_DEGREE = "/saveDegreeMaster";
export const DELETE_DEGREE = "/deleteDegreeMaster";
export const GET_DEGREE_BY_ID = "/getDegreeMasterById";

// Prefered Location

export const LIST_PREFERED_LOCATION = "/listPreferedLocationMaster";
export const ADD_PREFERED_LOCATION = "/savePreferedLocationMaster";
export const DELETE_PREFERED_LOCATION = "/deletePreferedLocationMaster";
export const GET_PREFERED_LOCATION_BY_ID = "/getPreferedLocationMasterById";

// Prefered Take Up

export const LIST_PREFERED_TAKE_UP = "/listPreferedTakeUpMaster";
export const ADD_PREFERED_TAKE_UP = "/savePreferedTakeUpMaster";
export const DELETE_PREFERED_TAKE_UP = "/deletePreferedTakeUpMaster";
export const GET_PREFERED_TAKE_UP_BY_ID = "/getPreferedTakeUpMasterById";

// Staff Management

export const LIST_STAFF_MANAGEMENT = "/listPreferedTakeUpMaster";
export const ADD_STAFF_MANAGEMENT = "/savePreferedTakeUpMaster";
export const DELETE_STAFF_MANAGEMENT = "/deletePreferedTakeUpMaster";
export const GET_STAFF_MANAGEMENT_BY_ID = "/getPreferedTakeUpMasterById";

export const LIST_DEPARTMENT_MASTER = "/getDepartmentList";
export const ADD_DEPARTMENT = "/addUpdateDepartmentData";
export const DELETE_DEPARTMENT = "/deleteDepartmentData";

// Industries

export const LIST_INDUSTRY = "/getindustriesList";
export const ADD_INDUSTRY = "/addUpdateIndustriesData";
export const DELETE_INDUSTRY = "/deleteIndustriesData";

// Job

export const LIST_JOB_MASTER = "/listjobTypeMaster";
export const ADD_JOB_TYPE = "/saveJobTypeMaster";
export const DELETE_JOB_TYPE = "/deleteJobTypeMaster";
export const GET_JOB_TYPE_BY_ID = "/getJobTypeMasterById";

// Regional language preference

export const LIST_REGIONAL_LANGUAGE_PREFERENCE_MASTER =
  "/listRegionalLanguagePrefrenceMaster";
export const ADD_REGIONAL_LANGUAGE_PREFERENCE_MASTER =
  "/saveRegionalLanguagePrefrenceMaster";
export const EDIT_REGIONAL_LANGUAGE_PREFERENCE_MASTER =
  "/getRegionalLanguagePrefrenceMasterById";
export const DELETE_REGIONAL_LANGUAGE_PREFERENCE_MASTER =
  "/deleteRegionalLanguagePrefrenceMaster";

// Skill

export const LIST_SKILLS_MASTER = "/listSkillsMaster";
export const ADD_SKILL = "/saveSkillsMaster";
export const DELETE_SKILL = "/deleteSkillsMaster";
export const GET_SKILL_BY_ID = "/getSkillsMasterById";

// Asset

export const LIST_ASSETS_MASTER = "/listAssetsMaster";
export const ADD_ASSETS = "/saveAssetsMaster";
export const DELETE_ASSETS = "/deleteAssetsMaster";
export const GET_ASSETS_BY_ID = "/getAssetsMasterById";

// Vendor

export const LIST_VENDOR_MASTER = "/listVendor";
export const ADD_VENDOR = "/createVendor";
export const DELETE_VENDOR = "/deleteVendor";
export const GET_VENDOR_BY_ID = "/getVendorDetailsById";

// Role

export const GET_ROLES = "/listPermissionProfile";
export const ADD_ROLE = "/savePermissionProfile";
export const GER_ROLE_BY_ID = "/PermissionProfileDetail";
export const DELETE_ROLE = "/deletePermissionProfile";
