import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_REGIONAL_LANGUAGE_PREFERENCE_MASTER,
  DELETE_REGIONAL_LANGUAGE_PREFERENCE_MASTER,
  EDIT_REGIONAL_LANGUAGE_PREFERENCE_MASTER,
  LIST_REGIONAL_LANGUAGE_PREFERENCE_MASTER,
} from "constants/url";
import { axiosPehalGigAdmin } from "services/api";

export const GetAllRegional = createAsyncThunk(
  `gigs/GetAllRegional`,
  async (thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${LIST_REGIONAL_LANGUAGE_PREFERENCE_MASTER}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const AddUpdateRegional = createAsyncThunk(
  `gigs/AddUpdateRegional`,
  async ({ payload, cb }, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.post(
        ADD_REGIONAL_LANGUAGE_PREFERENCE_MASTER,
        payload
      );
      cb(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const EditRegional = createAsyncThunk(
  `gigs/getUserDetailsById`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPehalGigAdmin.get(
        `${EDIT_REGIONAL_LANGUAGE_PREFERENCE_MASTER}/${payload}`
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const DeleteRegional = createAsyncThunk(
  `gigs/DeleteJobTypes`,
  async (payload, thunkAPI) => {
    try {
      await axiosPehalGigAdmin.delete(
        `${DELETE_REGIONAL_LANGUAGE_PREFERENCE_MASTER}/${payload}`
      );
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data?.message);
    }
  }
);
