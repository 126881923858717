import { useCallback } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

// is for types routes | boolean
const useModulesPermission = ({
  arr = [],
  compareKey = "id",
  is_for = "routes",
  keyName = "",
}) => {
  const { user, isAuth, loading } = useSelector((state) => state.Auth);

  const roles = useMemo(
    () => user?.permission_profile?.permissions,
    [user?.permission_profile?.permissions]
  );

  const hasPermission = useCallback(() => {
    return !loading
      ? isAuth
        ? keyName
          ? JSON.parse(
              roles?.find((r) => {
                const json = JSON.parse(r?.json_value || null);
                return keyName === json?.key;
              })?.json_value || null
            )?.value
          : true
        : true
      : true;
  }, [isAuth, roles, loading, keyName]);

  const newRoutes = useCallback(
    () =>
      !loading
        ? isAuth
          ? arr.filter((route) => {
              return roles?.find((r) => {
                const json = JSON.parse(r?.json_value || null);
                return json && route?.[compareKey] === json?.key
                  ? !!json?.value
                  : false;
              });
            })
          : arr
        : arr,
    [isAuth, arr, roles, compareKey, loading]
  );

  const value = useMemo(() => {
    return is_for === "boolean"
      ? hasPermission()
      : is_for === "routes"
      ? newRoutes()
      : true;
  }, [is_for, hasPermission, newRoutes]);
  return value;
};

export default useModulesPermission;
